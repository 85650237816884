// project descriptions
const projectList = [
  {
    title: "flaskCompare",
    link: "https://flaskcompare.krzem.dev/",
    code: "https://github.com/krzemGit/flaskCompare",
    design: "https://www.dropbox.com/s/5gmty12vw36cy9z/Flask-compare-mockup-full.jpg?raw=1",
    tech: "Python / Flask / Javascript / SASS / BEM",
    para1: "My favourite project, inspired by my friend, who was looking for a house to buy. He said that he used Python for webscraping and made a database of interesring offers. I decided to do the same using Flask and Beautifulsoup. This compare app searches three auction portals (including a Polish portal), translates phrases to English and converts prices to USD. Results can be saved to a database.",
    para2: "The app is made with Python, Flask and Beautifulsoup. Results saved by users are stored in SQLite database, that has a set limit and an auto-cleaning function. The frontend was made with templating engine, but also with JavaScript and SASS.",
  },
  {
    title: "flaskPresent",
    link: "https://flaskpresent.krzem.dev/",
    code: "https://github.com/krzemGit/flaskTemplates",
    design: "https://www.dropbox.com/s/s4gcsu7xfu5uex5/flask_present_full.jpg?raw=1",
    tech: "Python / Flask / Javascript / SQLite",
    para1: "My first commercial project with python and Flask. A friend of mine, a back-end developer asked me to prepare some jinja templates that he could use for presenttion purposes for his app. After making the templates for him I added my own back-end and volia: a web app for making a present list. It includes several display options, one with a neumorphist design",
    para2: "In technical terms, the app is rather simple: a typical CRUD application with a simple SQLite database.The back-end is made with Python and Flask, nothing fency there. The visual side of the app was the emphasis here.",
  },
  {
    title: "flaskCredit",
    link: "https://flaskcredit.krzem.dev/",
    code: "https://github.com/krzemGit/flask_credit",
    design: "https://www.dropbox.com/s/b9xftn3ij71f2j9/flask_credit.jpg?raw=1",
    tech: "Python / Flask / Javascript / SQLite",
    para1: "A very basic app that checks a credit card number with Luhn algorithm and responds with the card company name. To avoid using real cards, the app has a modal with several fictional numbers that can be used for testing. The idea came from the CS50 course, where it was required to write this testing algorithm in C. I converted the code to Python and used it with Flask.",
    para2: "The app is written in Python and Flask - the number is tested with the algorithm and the response is sent via Flask to the user. Jinja template engine was used for the front-end display, with a little help of JavaScript.",
  },
  {
    title: "bitInfo",
    link: "https://bit-info-30y7d1biy.vercel.app/",
    code: "https://github.com/krzemGit/bitInfo",
    design: "https://www.krzem.dev/no-mockup",
    tech: "React.js / Next.js / SASS / BEM / API",
    para1: "Several years ago me brother and I got interested in BitCoins and the crypto-currency market in general. Because of this old hobby and the fact that crypto-currencies have quite well developed internet support, with various APIs I decided to make an app with news and current exchange rates. This is a very dynamic environment, so I decided that React would be the best tool for the job.",
    para2: "This is my second React app, so I decided to use test new. My choice was Next.js as an interesting React framework. Although I have used very few features from the range offered by Next, I find the tool interesting. The app is deployed to Vercel - a dedicated platform for Next.js and React apps in general. I used a graphic library for displaying exchange rates.",
  },
  {
    title: "waether-app",
    link: "https://weatherapp.krzem.dev/",
    code: "https://github.com/krzemGit/weather-app-cards",
    design: "https://www.dropbox.com/s/75ogop1yi1qqskv/polish_weather_app.jpg?dl=0",
    tech: "React.js / SASS / BEM / API",
    para1: "This is my first React.js project, a typical one for all kinds of courses and bootcamps, so I wanted to add  little flavour to it: the weather in Polish cities is displayed on cards, which you can navigate using buttons and touch events on mobile phones. I tried to make it visually appealing and a little more advanced, hence the touch events and error handling.",
    para2: "This app is pure front-end, created with React.js (CRA) and SASS. The weather data is collected form an API, login data is stored in global variable at a Linux server. This is my second favourite project.",
  },
  {
    title: "infoService",
    link: "https://info-service.herokuapp.com/",
    code: "https://github.com/krzemGit/weather-app-cards",
    design: "https://www.krzem.dev/no-mockup",
    tech: "Node.js / Express / SASS / MongoDB",
    para1: "The idea for this project emerged during the Node.js course that I have taken several months ago: a news portal where you can read and add your own news. Addiditonal option is a poll that you can take and then see the results displayed in a graphical form. The layout is modeled on polish news service that I find quite well-developed and visually appealing.",
    para2: "The backend of this project is made in Node.js, with use of Express and Mongoose. The data from the polls and the articles are stored in a MongoDB database using Atlas. For the front-end I used JavaScript and Pug templating engine.",
  },
  {
    title: "starTrekCrew",
    link: "https://star-trek-crew.herokuapp.com/",
    code: "https://www.krzem.dev/no-mockup",
    design: "https://www.krzem.dev/no-mockup",
    tech: "JavaScript / Node.js / Express.js",
    para1: "The app made for sci-fi fans - you can complete your own crew for a Star Trek expedition. Initially you have five crew members, which can be replaced or upgraded with new weapons or equipment. You can also add new members to the crew. In essence - it is a sci-fi CRUD app.",
    para2: "The backend for this app is made with Node.js and Express.js, the data is stored in file and not in a database - a difference form the infoService app. I have experimented with neon design and background pictures, so do not be distracted by strong contrasts.",
  },
  {
    title: "nodeServer",
    link: "http://node-serv1.herokuapp.com/",
    code: "https://github.com/krzemGit/nodeServer",
    design: "https://www.krzem.dev/no-mockup",
    tech: "Node.js / JavaScript",
    para1: "A very basic application made in pure Node.js, without using any external frameworks or libraries. The idea was to make a basic node server with a skeleton for further web pages. It was also my first Node.js server, so it is neither complicated nor complex.",
    para2: "In technicl terms there is not much happening at the backend - no database and no content that a user can change, but the emphasis was on defining MIME types, file types and serving statc files with pure Node.js.",
  },
  {
    title: "nodeQuest",
    link: "https://nodequest.krzem.dev/",
    code: "https://github.com/krzemGit/express-quiz",
    design: "https://www.dropbox.com/s/zzdb0eqwf71v7vw/nodeQuest.jpg?raw=1",
    tech: "Node.js / Express.js / MongoDB / JavaScript / BEM",
    para1: "One of three JavaScript games that I made, the most complex one, with backend and a database. The user has to answer 10 question related to programming, for the hard ones he/she can access help options(call to a friend, half/half and question to public). A game modeled after the millioners, where a winner can add own queston to the pool. ",
    para2: "The backend is essentially Node.js with Express, Mongoose and a MongoDB. The front and the backend communicate with a use of API. The front-end is created in an object-oriented paradigm, the backend includes several additional functions with random clues and an error margins for the hints. The game was a challenge, and it still has several minor bugs to tackle with.",
  },
  {
    title: "Jackpot",
    link: "https://krzemgit.github.io/jackpot/",
    code: "https://github.com/krzemGit/jackpot",
    design: "https://www.dropbox.com/s/eotb3hjau41fw3o/jackpot.jpg?dl=0",
    tech: "Node.js",
    para1: "This is another of three JavaScript online games made in an object-oriented paradigm. This one is a basic Jackpot with adjustable amount for bets and a spinning function for the three picture slots. A good game if you want to relax and check your luck. Enjoy!",
    para2: "The app is a pure front-end with HTML, CSS and JavaScript. The most challenging part was the spinning function, where I had to adjust the timing and the slowing speed for the spin. This was my first object-oriented app.",
  },
  {
    title: "rockPaper",
    link: "https://krzemgit.github.io/rock-paper-scissors/",
    code: "https://github.com/krzemGit/rock-paper-scissors",
    design: "https://www.krzem.dev/no-mockup",
    tech: "JavaScript / SASS / BEM",
    para1: "This one is the most basic of the JvaScript OOP games - rock / paper / scissors that you can play with a computer. Not very fency, the only additions are the draggable displays, an option useful for different screens, especiall for small, mobile devices. The game is not complicated, but you can still provide some entertainment.",
    para2: "This game is technically the least demanding and the design is not awsome, but it was on the first projects that I designed in the object-oriented manner. It is a pure fornt-end app, with use of HTML, CSS and JavaScript.",
  },
]

export default projectList;